<template>
  <div style="background-color: white">
    <div style="margin: 0 2% 0 2%;">
      <!--0 待审核-->
      <div v-if="status===50" class="text-body">
        <div>
          <div style="margin:0 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">基础信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="社区" v-model="dataList.communityName" />
              <van-cell title="服务类型" v-model="dataList.typeName"/>
              <van-cell title="服务名称" v-model="dataList.name"/>
              <van-cell title="请求者" v-model="dataList.userName"/>
              <van-cell title="请求时间" v-model="dataList.createDate"/>
              <van-cell title="房屋" v-model="dataList.fullName"/>
              <van-cell title="联系电话" v-model="dataList.mobile"/>
              <van-cell title="审核时间" v-model="dataList.checkDate"/>
              <van-cell title="服务单状态" v-model="dataList.statusName"></van-cell>
            </van-cell-group>
          </div>
        </div>

        <van-button round class="blue-btn" @click="show_if">撤销请求</van-button>
      </div>
      <!--1 审核不通过-->
      <div v-if="status===52">
        <div>
          <div style="margin:0 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">基础信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="社区" v-model="dataList.communityName" />
              <van-cell title="服务类型" v-model="dataList.typeName"/>
              <van-cell title="服务名称" v-model="dataList.name"/>
              <van-cell title="请求者" v-model="dataList.userName"/>
              <van-cell title="请求时间" v-model="dataList.createDate"/>
              <van-cell title="房屋" v-model="dataList.fullName"/>
              <van-cell title="联系电话" v-model="dataList.mobile"/>
              <van-cell title="审核时间" v-model="dataList.checkDate"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===0" value="无偿"/>
              <van-cell  title="服务收益" v-if="dataList.serviceProfit===1" value="低偿"/>
              <van-cell title="服务单状态" v-model="dataList.statusName"></van-cell>
              <van-cell title="不通过原因" style="" value=""/>
              <van-cell v-if="dataList.checkDes" class="desc">{{dataList.checkDes}}</van-cell>
            </van-cell-group>
          </div>
        </div>
        <van-button round class="blue-btn" @click="again">重新发起</van-button>
      </div>
      <!--2 待接单-->
      <div v-if="status===51">
        <div>
          <div style="margin:0 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">基础信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="社区" v-model="dataList.communityName" />
              <van-cell title="服务类型" v-model="dataList.typeName"/>
              <van-cell title="服务名称" v-model="dataList.name"/>
              <van-cell title="请求者" v-model="dataList.userName"/>
              <van-cell title="请求时间" v-model="dataList.createDate"/>
              <van-cell title="请求理由" v-model="dataList.serviceReason" v-if="dataList.community == '15303'"/>
              <van-cell title="房屋" v-model="dataList.fullName"/>
              <van-cell title="联系电话" v-model="dataList.mobile"/>
              <van-cell title="审核时间" v-model="dataList.checkDate"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===0" value="无偿"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===1" value="低偿"/>
              <van-cell title="服务单状态" v-model="dataList.statusName"></van-cell>
            </van-cell-group>
          </div>
        </div>
        <van-button round class="blue-btn" @click="backToList">返回</van-button>
      </div>
      <!--3 待评价-->
      <div v-if="status===30">
        <div>
          <div style="margin:0 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">基础信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="社区" v-model="dataList.communityName" />
              <van-cell title="服务类型" v-model="dataList.typeName"/>
              <van-cell title="服务名称" v-model="dataList.name"/>
              <van-cell title="请求者" v-model="dataList.userName"/>
              <van-cell title="请求时间" v-model="dataList.createDate"/>
              <van-cell title="请求理由" v-model="dataList.serviceReason" v-if="dataList.community == '15303'"/>
              <van-cell title="房屋" v-model="dataList.fullName"/>
              <van-cell title="联系电话" v-model="dataList.mobile"/>
              <van-cell title="审核时间" v-model="dataList.checkDate"/>
              <van-cell  title="服务收益" v-if="dataList.serviceProfit===0" value="无偿"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===1" value="低偿"/>
              <!--              <van-cell title="服务单状态" v-model="dataList.statusName"></van-cell>-->
              <!--              <van-cell title="服务状态" value-class="agree" value="审核通过，待接单"></van-cell>-->
              <van-cell title="审核意见" v-if="dataList.checkDes" value=""/>
              <van-cell v-if="dataList.checkDes" class="desc">{{dataList.checkDes}}</van-cell>
            </van-cell-group>
          </div>
        </div>
        <div>

          <div style="margin:2% 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">服务信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="服务者" v-model="dataList.provideUserName" />
              <van-cell title="接单时间" v-model="dataList.provideDate"/>
              <van-cell title="服务时间" v-model="dataList.serviceDate"/>
              <van-cell title="服务描述" style="" value=""/>
              <van-cell v-if="dataList.serviceDes" class="desc" v-model="dataList.serviceDes"></van-cell>
              <van-cell title="服务单状态" v-model="dataList.statusName"/>
              <van-row class="upload-height" v-if="fileList.length>0">
                <van-col>
                  <van-uploader :show-upload="false" :deletable="false" v-model="fileList"  :multiple="false" @delete="uploadDel" :after-read="uploadRead"/>
                </van-col>
              </van-row>
            </van-cell-group>
          </div>
        </div>
        <div>
          <div style="margin:2% 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">服务评价</div>
            </div>
          </div>
          <div style="display: flex;flex-direction:column;justify-items: center;align-items: center">
            <van-rate color="#ffa93a" v-model="rate"  :size="30"/>
            <div class="rate">
              <div v-if="this.rate===1 || this.rate===0">十分不满意</div>
              <div v-if="this.rate===2">不满意</div>
              <div v-if="this.rate===3">一般</div>
              <div v-if="this.rate===4">比较满意</div>
              <div v-if="this.rate===5">十分满意</div>
            </div>
          </div>
        </div>
        <div style="height: 80px;"></div>
        <van-button round class="blue-btn" @click="submitEvaluation">发布评价</van-button>


      </div>
      <!--4 已评价-->
      <div v-if="status===40">
        <!--基础信息-->
        <div>
          <div style="margin:0 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">基础信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="社区" v-model="dataList.communityName" />
              <van-cell title="服务类型" v-model="dataList.typeName"/>
              <van-cell title="服务名称" v-model="dataList.name"/>
              <van-cell title="请求者" v-model="dataList.userName"/>
              <van-cell title="请求时间" v-model="dataList.createDate"/>
              <van-cell title="请求理由" v-model="dataList.serviceReason" v-if="dataList.community == '15303'"/>
              <van-cell title="房屋" v-model="dataList.fullName"/>
              <van-cell title="联系电话" v-model="dataList.mobile"/>
              <van-cell title="审核时间" v-model="dataList.checkDate"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===0" value="无偿"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===1" value="低偿"/>
              <!--              <van-cell title="服务状态" value-class="agree" value="审核通过，待接单"></van-cell>-->
              <van-cell title="审核意见" v-if="dataList.checkDes" value=""/>
              <van-cell class="desc" v-if="dataList.checkDes" v-model="dataList.checkDes"></van-cell>            </van-cell-group>
          </div>
        </div>
        <!--服务信息-->
        <div>

          <div style="margin:2% 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">服务信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="服务者" v-model="dataList.provideUserName" />
              <van-cell title="接单时间" v-model="dataList.provideDate"/>
              <van-cell title="服务时间" v-model="dataList.serviceDate"/>
              <van-cell title="服务描述" style="" value=""/>
              <van-cell v-if="dataList.serviceDes" class="desc" v-model="dataList.serviceDes"></van-cell>
              <van-row class="upload-height" v-if="fileList.length>0">
                <div style="margin-left: 2%;display: flex;margin-top: 20px;">
                  <van-uploader disabled :multiple="false" :show-upload="false" :deletable="false" v-model="fileList"></van-uploader>
                </div>
              </van-row>

            </van-cell-group>
          </div>
        </div>
        <!--评价信息-->
        <div>
          <div style="margin:2% 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">服务评价</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="评价" value="" >
                <van-rate disabled-color="#ffa93a" disabled v-model="dataList.evaluate"></van-rate>
              </van-cell>
              <van-cell title="评价时间" v-model="dataList.evaluateTime"/>
              <van-cell title="服务单状态" value-class="comment_have" value="已服务,已评价"/>
            </van-cell-group>
          </div>
          <div style="height: 80px;"></div>
        </div>

        <!--        <van-button round style="background-color:#387FF5;color:white;width: 92%;margin-left: 4%;margin-top: 5%;margin-bottom: 2%;" @click="">发布评价</van-button>-->
      </div>
      <!--20 待服务-->
      <div v-if="status===20">
        <!--基础信息-->
        <div>
          <div style="margin:0 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">基础信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="社区" v-model="dataList.communityName" />
              <van-cell title="服务类型" v-model="dataList.typeName"/>
              <van-cell title="服务名称" v-model="dataList.name"/>
              <van-cell title="请求者" v-model="dataList.userName"/>
              <van-cell title="请求时间" v-model="dataList.createDate"/>
              <van-cell title="请求理由" v-model="dataList.serviceReason" v-if="dataList.community == '15303'"/>
              <van-cell title="房屋" v-model="dataList.fullName"/>
              <van-cell title="联系电话" v-model="dataList.mobile"/>
              <van-cell title="审核时间" v-model="dataList.checkDate"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===0" value="无偿"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===1" value="低偿"/>
              <!--              <van-cell title="服务单状态" v-model="dataList.statusName"></van-cell>-->
              <van-cell title="审核意见" v-if="dataList.checkDes" value=""/>
              <van-cell class="desc" v-if="dataList.checkDes" v-model="dataList.checkDes"></van-cell>
            </van-cell-group>
          </div>
        </div>
        <!--服务信息-->
        <div>

          <div style="margin:2% 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">服务信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="服务者" v-model="dataList.provideUserName" />
              <van-cell title="接单时间" v-model="dataList.provideDate"/>
              <van-cell title="服务时间" v-model="dataList.serviceDate"/>
              <van-cell title="服务描述" style="" value=""/>
              <van-cell v-if="dataList.serviceDes" class="desc" v-model="dataList.serviceDes"></van-cell>
              <van-cell title="服务单状态" value-class="comment" v-model="dataList.statusName"/>
            </van-cell-group>
          </div>
        </div>
        <van-button round class="blue-btn" @click="backToList()">返回</van-button>
      </div>
      <!--54 服务者撤销-->
      <div v-if="status===54">
        <!--基础信息-->
        <div>
          <div style="margin:0 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">基础信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="社区" v-model="dataList.communityName" />
              <van-cell title="服务类型" v-model="dataList.typeName"/>
              <van-cell title="服务名称" v-model="dataList.name"/>
              <van-cell title="请求者" v-model="dataList.userName"/>
              <van-cell title="请求时间" v-model="dataList.createDate"/>
              <van-cell title="请求理由" v-model="dataList.serviceReason" v-if="dataList.community == '15303'"/>
              <van-cell title="房屋" v-model="dataList.fullName"/>
              <van-cell title="联系电话" v-model="dataList.mobile"/>
              <van-cell title="审核时间" v-model="dataList.checkDate"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===0" value="无偿"/>
              <van-cell title="服务收益" v-if="dataList.serviceProfit===1" value="低偿"/>
              <!--              <van-cell title="服务单状态" v-model="dataList.statusName"></van-cell>-->
              <van-cell title="审核意见" v-if="dataList.checkDes" value=""/>
              <van-cell class="desc" v-if="dataList.checkDes" v-model="dataList.checkDes"></van-cell>
            </van-cell-group>
          </div>
        </div>
        <van-button round class="blue-btn" @click="backToList()">返回</van-button>
      </div>
      <!--53 居民撤销-->
      <div v-if="status===53">
        <!--基础信息-->
        <div>
          <div style="margin:0 0 4% 4%">
            <div class="base-info-height">
              <div class="blue-line"></div>
              <div class="base-info-label">基础信息</div>
            </div>
          </div>
          <div>
            <van-cell-group>
              <van-cell title="社区" v-model="dataList.communityName" />
              <van-cell title="服务类型" v-model="dataList.typeName"/>
              <van-cell title="服务名称" v-model="dataList.name"/>
              <van-cell title="请求者" v-model="dataList.userName"/>
              <van-cell title="请求时间" v-model="dataList.createDate"/>
              <van-cell title="请求理由" v-model="dataList.serviceReason" v-if="dataList.community == '15303'"/>
              <van-cell title="房屋" v-model="dataList.fullName"/>
              <van-cell title="联系电话" v-model="dataList.mobile"/>
              <van-cell title="审核时间" v-model="dataList.checkDate"/>
            </van-cell-group>
          </div>
        </div>
        <van-button round class="blue-btn" @click="backToList()">返回</van-button>
      </div>
    </div>


    <van-dialog show-cancel-button v-model="show_if_back" message="是否确定撤销" @confirm="backApplication">
    </van-dialog>

  </div>


</template>

<script>

import topBar from '@/components/topBar/topBar'
import {upload} from "@/utils/common";

export default {

  name: "request-detail",

  components: {
    topBar
  },
  data(){
    return{
      temp_url:"http://uht.vaiwan.com/iapi/",
      id:null,
      checkStatus:null,
      show:false,
      show_1:false,
      show_if_back:false,
      status:2,
      fileList: [],
      description:null,
      rate:5,
      rateResult:3,
      dataList: {},
    }
  },
  mounted() {
    // console.log(this.$route.params.requestStatus)
    this.status = this.$route.params.requestStatus
    this.id = this.$route.params.id
    this.checkStatus = this.$route.params.checkStatus
    console.log(this.status)
    this.getDataList()
  },
  methods:{
    again(){
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appserviceapply/update"),
        method: "post",
        data:this.$httpApp.adornData({
          id:this.id,
          serviceId:this.dataList.serviceId,
          applyUserId:this.dataList.applyUserId,
          subarea:this.dataList.subarea
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.$notify({type:'success',message:"重新申请成功"})
        }else{
          this.$notify({type:'danger',message:data.msg})

        }
        this.$router.go(-1)
      });
    },
    getDataList(){
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appserviceapply/info/"+this.id),
        method: "post",
        params:this.$httpApp.adornParams({
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.dataList = data.appServiceApply
          // this.dataList.fileMessages.forEach(item => this.fileList.push({url: "http://uht.vaiwan.com/iapi/"+item.relativePath}))
          if(this.dataList.fileMessages!==null){
            this.dataList.fileMessages.forEach(item => this.fileList.push({url: item.objectUrl,type:item.fileType}))
          }

          console.log(this.fileList)

        }
      });

    },
    backApplication(){
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appserviceapply/revokeService"),
        method: "post",
        data:this.$httpApp.adornData({
          id:this.id,
          status:53
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.$notify({type:"success",message:"撤销成功"})
          this.$router.go(-1)
          this.getDataList()
        }else{
          this.$notify({type:"danger",message:"撤销失败"})
        }
        this.$route.go(-1)
        this.getDataList()
      });
    },
    show_if(){
      this.show_if_back = true
    },
    backToList(){
      this.$router.go(-1)
    },
    application(){
      this.show = true
    },
    showUploadResult(){
      this.show_1 = true
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    // 删除图片
    uploadDel (file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
    },
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      upload(file.file,(data)=> {
        this.$toast.clear()
        if(data.fileMessage) {
          this.submitData.newFileMessages.push(data.fileMessage)
          this.submitData.fileMessage.push(data.fileMessage)
        }else {
          this.$toast.console.error({message: '上传失败'})
        }
      })
    },
    checkContent(){
      if (this.description==null){
        return false
      }
    },
    submitEvaluation(){
      this.$httpApp({
        url:this.$httpApp.adornUrl("/wxapp/app/appserviceapply/serviceEvaluation"),
        method:"post",
        data:this.$httpApp.adornData({
          id:this.id,
          evaluate:this.rate,

        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$notify({type:"success",message:"评价成功"})
        }else{
          this.$notify({type:"warning",message:"评价失败"})
        }
        this.$router.go(-1)
        this.getDataList()
      })
    }

  }
}
</script>

<style scoped lang="scss" >
.blue-line{
  position:absolute;
  height:30%;
  margin-top:0.52rem;
  background-color: #0C8DF7;
  width: 0.05rem;
  display: inline-block
}
.base-info-label{
  position:absolute;
  margin-top:0.48rem;
  left:2%;
  display:inline-block;
  font-size: 30px;
  font-weight: bold;
}
.desc{
  background-color: #F5F5F5;
  font-size: 25px;
  border-radius: 1%;
  width: 92%;
  margin-left: 4%
}

.blue-btn{
  background-color:#387FF5;
  color:white;
  width: 92%;
  margin-left: 2%;
  //margin-top: 15%;
  //margin-bottom: 20%;
  position:fixed;
  bottom:25px;
}

.server-info{
  width: 100%;
  height: 0.2rem;
  background-color: #F5F5F5;
  margin-top: 10px;
}
.base-info-height{
  height: 80px;
  position: relative;
}
.line-sp{
  width: 100%;
  height: 16px;
  background-color: #F5F5F5;
  margin-top: 20px;
}

.upload-height{
  margin-top: 40px;
  margin-left: 4%;
}
.back-btn{
  width:45%;
  height: 85px;
  margin-top: 8%;
  margin-bottom:20%;
  margin-left: 4%;
  background-color: #ed4747;
  color:white
}
.finish-btn{
  width: 45%;
  margin-top: 8%;
  margin-bottom:20%;
  margin-left: 2%;
  height: 85px;
  background-color:#387ff5;
  color:white
}

.win-body{
  height:400px;
  width:600px;
  background-color:white;
  position: absolute;
  left: 50%;
  top: 48%;
  margin-top: -200px;
  margin-left: -300px;
  border-radius: 30px;
}

.close-btn{
  position: absolute;
  right: 5%;
  margin-top: 3%;
  font-size: 40px
}

.win-text{
  position: absolute;
  font-size: 32px;
  margin-top: 20%;
  margin-left: 25px;
  line-height: 40px;
}

.win-bottom{
  position:absolute;
  margin-top: 280px;
  left: 100px;
}

.win-confirm-btn{
  border-radius: 60px;
  width: 400px;
  background-color: #387ff5;
  color: white
}
.rate{
  margin-top:3%;
  font-size: 32px;
}

.van-cell-group::after{
  border-width: 0.02667rem 0 0 0;
}
</style>
